import React, { useEffect, useState } from "react";

import FormActions from "./FormActions";
import Dropzone from "../../components/Dropzone";
import Loading from "../../components/Loading/index";
import Header from "../tables/Header";
import { Flex, Text, View, useTheme } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";
import Select from "react-select";

const customStyles = {
  control: (base) => ({
    ...base,
    padding: "2.5px",
    border: "1px solid #0d1a26",
    "&:hover": {
      border: "1px solid #0d1a26",
      cursor: "pointer",
    },
    boxShadow: "none",
  }),
  option: (base, { isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isSelected
        ? "#173660"
        : isFocused
        ? "#f0f0f0"
        : "transparent",
      "&:hover": {
        cursor: "pointer",
      },
    };
  },
};

const defineLabel = (label, required = true) => (
  <Text>
    {label}&nbsp;
    <Text
      as="span"
      fontSize="0.8rem"
      color={required ? "#dac57a" : "gray"}
      fontWeight="800"
    >
      {required === null ? "" : `(${required ? "required" : "optional"})`}
    </Text>
  </Text>
);

const UploadForm = () => {
  const { uploadFile, uploadProgress, loading, getMissions, missions, isUser } =
    useContx();
  const [file, setFile] = useState<any>(null);
  const [missionId, setMissionId] = useState<string>("");

  const { tokens } = useTheme();

  useEffect(() => {
    if (isUser) {
      getMissions();
    }
    //eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    await uploadFile({ file, mission_id: missionId });
    setFile(null);
  };

  const missionOptions = missions?.map((x) => ({
    label: x.name,
    value: x.id,
  }));

  return (
    <>
      <Header title="Upload File" />
      <Flex
        direction={{ base: "column", large: "row" }}
        alignItems="stretch"
        gap={tokens.space.xl}
        minHeight="19vh"
      >
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "70%" }}
          padding="1rem"
          minHeight="19vh"
        >
          <Flex direction="column" width="100%">
            <div>
              {defineLabel("Expedition")}
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={""}
                isDisabled={loading}
                required={true}
                isSearchable={true}
                isClearable={true}
                placeholder={"Please select expedition"}
                styles={customStyles}
                // @ts-ignore
                options={missionOptions}
                // @ts-ignore
                onChange={(mission) => setMissionId(mission?.value)}
              />
            </div>
            <Dropzone
              onChange={(files) => setFile(files[0])}
              disabled={loading}
              file={file}
            />
          </Flex>
        </View>
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "30%" }}
          padding="1rem"
          minHeight="19vh"
        >
          <FormActions
            text="Upload"
            loadingText="Uploading..."
            formOnSubmit={onSubmit}
            isLoading={loading}
            isDisabled={loading || !file || !missionId}
          />
          {loading && <Loading percentage={uploadProgress} />}
        </View>
      </Flex>
    </>
  );
};

export default UploadForm;

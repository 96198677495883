import React from "react";

import { View } from "@aws-amplify/ui-react";

const Wrapper = ({ children }) => (
  <View
    backgroundColor="var(--amplify-colors-white)"
    borderRadius="6px"
    maxWidth="100%"
    padding="1rem"
    minHeight="80vh"
  >
    {children}
  </View>
);

export default Wrapper;

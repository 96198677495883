import React, { useEffect } from "react";

import Config from "../../config";
import BoatsTable from "./BoatsTable";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import Wrapper from "../../components/Wrapper";
import CustomButton from "../../components/CustomButton";

import { ScrollView, Flex } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";

const BoatsTablePage = () => {
  const { isUser, boats, getBoats, loading, error } = useContx();

  useEffect(() => {
    isUser && getBoats();
    //eslint-disable-next-line
  }, []);

  let content = (
    <>
      <Flex alignItems="center" padding={10}>
        <CustomButton
          path={Config.routes.addLocation}
          state="Location"
          check={isUser}
        />
        <CustomButton
          margin="inherit"
          path={Config.routes.addBoat}
          state="Boat"
          check={isUser}
        />
      </Flex>
      <Wrapper>
        <br></br>
        <ScrollView width="100%">
          <BoatsTable boats={boats} />
        </ScrollView>
      </Wrapper>
    </>
  );

  if (loading) {
    content = <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return content;
};

export default BoatsTablePage;

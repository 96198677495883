import React from "react";

import { Icon } from "@aws-amplify/ui-react";
import {
  MdAccountBalance,
  MdAccountBox,
  MdOutlineInsertChart,
  MdOutlineUploadFile,
  MdDirectionsBoat,
} from "react-icons/md";
import { BiTargetLock } from "react-icons/bi";

const Config = {
  projectLink: "/",
  docsRepositoryBase: "",
  titleSuffix: "",
  search: false,
  header: true,
  headerText: "MASHIEN",
  footer: false,
  footerText: (
    <>
      <span style={{ fontWeight: 700, fontSize: "20px" }}>MASHIEN</span>
    </>
  ),

  logo: (
    <>
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="logo"
        width="30"
        height="30"
      />
    </>
  ),

  appNavs: [
    {
      eventKey: "agencies",
      icon: <Icon as={MdAccountBalance} />,
      title: "Agencies",
      to: "/",
      role: "admin",
    },

    {
      eventKey: "users",
      icon: <Icon as={MdAccountBox} />,
      title: "Users",
      to: "/users",
      role: "admin",
    },

    {
      eventKey: "logs",
      icon: <Icon as={MdOutlineUploadFile} />,
      title: "Logs",
      to: "/",
      role: "agency_admin",
    },

    {
      eventKey: "boats",
      icon: <Icon as={MdDirectionsBoat} />,
      title: "Boats",
      to: "/boats",
      role: "agency_admin",
    },

    {
      eventKey: "expeditions",
      icon: <Icon as={BiTargetLock} />,
      title: "Expeditions",
      to: "/expeditions",
      role: "agency_admin",
    },

    {
      eventKey: "operators",
      icon: <Icon as={MdAccountBox} />,
      title: "Operators",
      to: "/operators",
      role: "agency_admin",
    },

    {
      eventKey: "dashboard",
      icon: <Icon as={MdOutlineInsertChart} />,
      title: "Dashboard",
      to: process.env.REACT_APP_SUPERSET_ENDPOINT,
      role: "all",
      external: true,
    },
  ],

  apiPaths: {
    agencies: "/agencies",
    agenciesById: (id) => `/agencies/${id}`,
    users: "/users",
    usersById: (id) => `/users/${id}`,
    files: "/datafiles",
    logs: "/logs",
    missions: "/mission",
    assignMission: (id) => `/mission/${id}/assign`,
    updateMission: (id) => `/mission/${id}`,
    boats: "/boat",
    updateBoat: (id) => `/boat/${id}`,
    location: "/location",
    country: "/country",
    userLogs: (id) => `/users/${id}/logs`,
  },

  // user = agency_admin
  // reporter = operator
  // mission = expedition

  roles: {
    admin: "admin",
    user: "agency_admin",
    researcher: "researcher",
    pain_reporter: "pain_reporter",
  },

  routes: {
    agencies: "/",
    users: "/users",
    profile: "/profile",
    addAgency: "/add-agency",
    updateAgency: "update-agency",
    addUser: "/add-user",
    uploadFile: "/upload-file",
    reporters: "/operators",
    boats: "/boats",
    addBoat: "/add-boat",
    updateBoat: "/update-boat",
    addLocation: "/add-location",
    missions: "/expeditions",
    addMission: "/add-expedition",
    updateMission: "/update-expedition",
    assignToAMission: "/assign-to-a-expedition",
    reporterLogs: "/operator-logs",
    dashboard: "/dashboard",
  },

  messages: {
    agencyCreated: "Agency created.",
    userCreated: "User created.",
    missionCreated: "Expedition created.",
    missionUpdated: "Expedition updated.",
    boatCreated: "Boat created.",
    boatUpdated: "Boat updated",
    locationCreated: "Location created.",
    agencyUpdated: "Agency updated.",
    fileUploaded: "File uploaded.",
    missionAssigned: "Expedition assigned.",
    passwordDoesNotMatch: "New Password and Confirm Password does not match.",
    passwordChanged: "Password changed.",
    userStatusUpdated: "User status updated."
  },
};

export default Config;

import React, { useEffect } from "react";

import { embedDashboard } from "@superset-ui/embedded-sdk";

import "./index.css";

const Dashboard = () => {
  let divElement: any = HTMLDivElement;

  useEffect(() => {
    if (divElement) {
      embedDashboard({
        id: "1",
        supersetDomain: process.env.REACT_APP_SUPERSET_DOMAIN,
        mountPoint: divElement,
        fetchGuestToken: async () =>
          new Promise((resolve) => resolve(process.env.REACT_APP_DASHBOARD_TOKEN)),
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
          },
        },
      });
    }
    //eslint-disable-next-line
  }, []);

  return <div style={{ width: '100%' }} ref={(el) => (divElement = el)}></div>;
};

export default Dashboard;

import React, { useState } from "react";

import Config from "../../../config";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
} from "@aws-amplify/ui-react";
import { BoatsTableProps } from "@/types";
import { defineSortIcon } from "../../../utils";
import { useNavigate } from "react-router-dom";

const BoatsTable = (props: BoatsTableProps) => {
  const { boats } = props;
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...boats].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return boats;
  }, [boats, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const header = (
    <TableRow>
      <TableCell as="th" onClick={() => requestSort("name")} className="hh hhb">
        Name
        {defineSortIcon(sortConfig.key, sortConfig.direction, "name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("model")}
        className="hh hhb"
      >
        Model
        {defineSortIcon(sortConfig.key, sortConfig.direction, "model")}
      </TableCell>
      <TableCell as="th" className="hh">
        Location
      </TableCell>
      <TableCell as="th" className="hh">
        Country Code
      </TableCell>
      <TableCell as="th" className="hh">
        Actions
      </TableCell>
    </TableRow>
  );

  const content = sortedData?.map((x) => (
    <TableRow key={x.id}>
      <TableCell>{x.name}</TableCell>
      <TableCell>{x.model}</TableCell>
      <TableCell>{x?.location?.name}</TableCell>
      <TableCell>{x?.location?.country_code}</TableCell>
      <TableCell>
        <Button
          onClick={() => navigate(Config.routes.updateBoat, { state: x })}
        >
          Update
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Table caption="">
        <TableHead>{header}</TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </>
  );
};

export default BoatsTable;

import React, { useEffect } from "react";

import Config from "../../config";
import LogsTable from "./LogsTable";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import CustomButton from "../../components/CustomButton";
import Pagination from "../../components/Pagination";

import { View, ScrollView, Flex } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";

const LogsTablePage = () => {
  const { isUser, getLogs, logs, error, loading, pagination, totals } = useContx();

  useEffect(() => {
    isUser && getLogs(1);
    //eslint-disable-next-line
  }, []);

  let l = logs;

  l = logs.slice(
    (pagination?.logs?.currentPage - 1) * pagination?.perPage,
    pagination?.logs?.currentPage * pagination?.perPage
  );

  let content = (
    <>
      <Flex alignItems="center" padding={10}>
        <CustomButton
          path={Config.routes.uploadFile}
          state="File"
          check={isUser}
        />
      </Flex>
      <div>
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          maxWidth="100%"
          padding="1rem"
          minHeight="80vh"
        >
          <ScrollView width="100%">
            <LogsTable logs={l} />
            <Pagination
              fn={getLogs}
              cp={pagination?.agencies?.currentPage}
              pp={pagination?.perPage}
              t={totals?.agencies?.total}
              v={totals?.agencies?.visited}
            />
          </ScrollView>
        </View>
      </div>
    </>
  );

  if (loading) {
    content = <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return content;
};

export default LogsTablePage;

import React from "react";
import Select from "react-select";
import { Text } from "@aws-amplify/ui-react";

const defineLabel = (label, required = true) => (
  <Text marginBottom={8}>
    {label}&nbsp;
    <Text
      as="span"
      fontSize="0.8rem"
      color={required ? "#dac57a" : "gray"}
      fontWeight="800"
    >
      {required === null ? "" : `(${required ? "required" : "optional"})`}
    </Text>
  </Text>
);

const customStyles = {
  control: (base) => ({
    ...base,
    padding: "2.5px",
    border: "1px solid #89949f",
    "&:hover": {
      border: "1px solid #89949f",
      cursor: "pointer",
    },
    boxShadow: "none",
  }),
  option: (base, { isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isSelected
        ? "#173660"
        : isFocused
        ? "#f0f0f0"
        : "transparent",
      "&:hover": {
        cursor: "pointer",
      },
    };
  },
};

const SearchSelect = ({
  options,
  label,
  loading,
  setFn,
  disabledArrCheck = [],
  disabled = false,
  required = true,
  isRequired = true,
  defaultValue = "",
  customKey = "",
}) => {
  let opt = options.map((x) => ({
    label: x?.model
      ? `${x.name} [${x.model} / ${x?.location?.name}]`
      : x?.country_code
      ? `${x.name} - ${x.country_code}`
      : x.name,
    value: x?.id || x?.code,
    isDisabled:
      disabledArrCheck && disabledArrCheck.length
        ? disabledArrCheck.includes(x.id)
        : false,
  }));

  const dv = opt?.find((x) => x.value === defaultValue);

  return (
    <div>
      {defineLabel(label, required)}
      <Select
        key={customKey}
        className="basic-single"
        classNamePrefix="select"
        defaultValue={dv || {}}
        isDisabled={loading || disabled}
        required={isRequired}
        isSearchable={true}
        isClearable={true}
        placeholder="Select..."
        styles={customStyles}
        // @ts-ignore
        options={opt}
        // @ts-ignore
        onChange={(data) => setFn(data?.value)}
      />
    </div>
  );
};

export default SearchSelect;

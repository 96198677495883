import React from "react";

import Config from "../../config";
import SideBar from "../SideBar";
import Header from "../Header";
import Footer from "../Footer";
import Spinner from "../Spinner";
import { useContx } from "../../AuthProvider";
import { Outlet } from "react-router-dom";

import "./index.css";

const Layout = () => {
  const { isUser, isAdmin, isResearcher, user } = useContx();

  if (!isUser && !isAdmin && !isResearcher) {
    return <Spinner />;
  }

  return (
    <div className="layout-container">
      {Config.header ? <Header agency={user['agency_name']}/> : <></>}
      <SideBar />
      <div className={`page-container ${isResearcher ? Config.roles.researcher : ""}`}>
        <Outlet />
      </div>
      {Config.footer ? <Footer /> : <></>}
    </div>
  );
};

export default Layout;

import React from "react";

import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";
import { ProfileInformationProps } from "@/types";

const ProfileInformation = ({
  changePw,
  setChangePw,
}: ProfileInformationProps) => {
  const { user } = useContx();

  return (
    <div className="profile-card-content">
      <Text fontWeight="600" fontSize="18px" marginBottom="14px">
        Information
      </Text>
      <Flex>
        <Text variation="tertiary" fontWeight="700">
          id
        </Text>
        <Text variation="tertiary">{user["id"]}</Text>
      </Flex>
      <Flex>
        <Text variation="tertiary" fontWeight="700">
          Email
        </Text>
        <Text variation="tertiary">{user["email"]}</Text>
      </Flex>
      <Flex>
        <Text variation="tertiary" fontWeight="700">
          Role
        </Text>
        <Text variation="tertiary">{user["role"]}</Text>
      </Flex>
      <Flex height="24.2vh" justifyContent="flex-end" alignItems="flex-end">
        <Button onClick={() => setChangePw((pv) => !pv)}>
          {changePw ? "Close" : "Change Password"}
        </Button>
      </Flex>
    </div>
  );
};

export default ProfileInformation;

import React from "react";
import { Autocomplete } from "@aws-amplify/ui-react";

//import { Config } from "../../config";

const HeaderSearchBar = () => {
  // const navigate = useNavigate();
  return (
    <div className="header-search-bar">
      <Autocomplete
        label="Autocomplete"
        options={[
          { id: "files", label: "Files" },
          { id: "users", label: "Users" },
        ]}
        placeholder="Search here..."
        size="small"
      />
    </div>
  );
};

export default HeaderSearchBar;

import React from "react";

import Config from "../../config";

import { Menu, MenuItem, MenuButton, Icon, Flex } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { useContx } from "../../AuthProvider";
import { MdAdminPanelSettings } from "react-icons/md";
import { HeaderProps } from "../../types";

const generateShortName = (email, isAdmin) => {
  if (!email) return "";
  let name = email.charAt(0).toUpperCase();
  if (isAdmin) {
    name = (
      <>
        {name}
        <Icon
          style={{ fontSize: "18px", marginLeft: "4px" }}
          as={MdAdminPanelSettings}
        />
      </>
    );
  }
  return name;
};

const HeaderNav = (props: HeaderProps) => {
  const { user, logout, isAdmin } = useContx();
  const navigate = useNavigate();

  return (
    <>
      <Flex alignItems="center">
        {!isAdmin && (<p className="header-agency">({props.agency})</p>)}
        <Menu
          menuAlign="end"
          trigger={
            <MenuButton variation="menu" style={{ border: "1px solid gray" }}>
              {generateShortName(user["email"], isAdmin)}
            </MenuButton>
          }
        >
          <MenuItem onClick={() => navigate(Config.routes.profile)}>
            Profile
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </Flex>
    </>
  );
};

export default HeaderNav;

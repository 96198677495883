export const columns = [
  {
    title: "Log Type",
    dataIndex: "logType",
    key: "logType",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

export const boatColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Attributes",
    dataIndex: "attributes",
    key: "attributes",
  },
];

export const answerColumns = [
  {
    title: "Question",
    dataIndex: "question",
    key: "question",
  },
  {
    title: "Answer",
    dataIndex: "answer",
    key: "answer",
  },
  {
    title: "Options",
    dataIndex: "options",
    key: "options",
  },
];

export const painColumns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Body Part",
    dataIndex: "bodyPart",
    key: "bodyPart",
  },
  {
    title: "Pain Type",
    dataIndex: "painType",
    key: "painType",
  },
  {
    title: "Pain Description",
    dataIndex: "painDescription",
    key: "painDescription",
  },
  {
    title: "Intensity",
    dataIndex: "intensity",
    key: "intensity",
  },
  {
    title: "Medication",
    dataIndex: "medication",
    key: "medication",
  },
]

import React, { useEffect, useState } from "react";

import Header from "../tables/Header";
import FormActions from "./FormActions";
import Config from "../../config";
import { Flex, Text, TextField, View, useTheme } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";
import { useLocation } from "react-router-dom";
import { UpdateFormProps } from "@/types";
import SearchSelect from "../../components/SearchSelect";

const fieldLabel = (label) => (
  <Text>
    {label}&nbsp;
    <Text as="span" fontSize="0.8rem" color="#dac57a" fontWeight="800">
      (required)
    </Text>
  </Text>
);

const UpdateForm = ({ title }: UpdateFormProps) => {
  const {
    loading,
    agencies,
    boats,
    locations,
    getAllAgencies,
    updateAgency,
    getLocations,
    updateBoat,
    updateMission,
    getBoats,
  } = useContx();
  const { tokens } = useTheme();
  const { state } = useLocation();
  const [agencyId, setAgencyId] = useState<string>(state?.agency_id);
  const [name, setName] = useState<string>(state?.name);
  const [model, setModel] = useState<string>(state?.model);
  const [email, setEmail] = useState<string>(state?.email);
  const [role, setRole] = useState<string>(state?.role);
  const [locationId, setLocationId] = useState<string>(state?.location?.id);
  const [startDate, setStartDate] = useState<string>(
    state?.date_start?.split(" ")[0]
  );
  const [endDate, setEndDate] = useState<string>(
    state?.date_end?.split(" ")[0]
  );
  const [boatId, setBoatId] = useState<string>(state?.boat_id);

  const isUser = title === "User";
  const isAgency = title === "Agency";
  const isBoat = title === "Boat";
  const isMission = title === "Expedition";

  const roleOptions = Object.values(Config.roles)
    .filter((x) => x !== Config.roles.admin)
    .map((y) => `${y.charAt(0).toUpperCase()}${y.slice(1)}`);

  useEffect(() => {
    if (isUser) {
      getAllAgencies();
    }
    if (isBoat) {
      getLocations();
    }
    if (isMission) {
      getBoats();
    }
    //eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    if (isAgency) {
      await updateAgency({ id: state.id, name });
    }
    if (isBoat) {
      await updateBoat({ id: state.id, name, model, location_id: locationId });
    }
    if (isMission) {
      await updateMission({
        id: state.id,
        name,
        date_start: startDate,
        date_end: endDate,
        boat_id: boatId,
      });
    }
    if (isUser) {
      // If user management is needed.
    }
  };

  const onDatesChange = (date, period) => {
    const val = date.split("-")[0]?.length;
    if (val >= 5) return;
    if (period === "start") {
      setStartDate(date);
    }
    if (period === "end") {
      setEndDate(date);
    }
  };

  let isDisabledCheck = false;

  if (isUser) {
    isDisabledCheck = loading || !agencyId || !name || !email || !role;
  }

  if (isAgency) {
    isDisabledCheck = loading || !name;
  }

  if (isMission) {
    isDisabledCheck = loading || !name || !startDate || !endDate || !boatId;
  }

  let content = null;

  if (isAgency) {
    content = (
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={fieldLabel("Name")}
        type="text"
        isRequired={true}
        isDisabled={loading}
      />
    );
  }

  if (isBoat) {
    content = (
      <>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={fieldLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <TextField
          value={model}
          onChange={(e) => setModel(e.target.value)}
          label={fieldLabel("Model")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <SearchSelect
          defaultValue={locationId}
          label="Please select location"
          options={locations}
          loading={loading}
          setFn={setLocationId}
        />
      </>
    );
  }

  if (isUser) {
    content = (
      <>
        <SearchSelect
          label="Please select agency"
          options={agencies}
          loading={loading}
          setFn={setAgencyId}
          disabled={loading}
          defaultValue={agencyId}
        />
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={fieldLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label={fieldLabel("Email")}
          type="text"
          isRequired={true}
          isDisabled={true}
        />
        <SearchSelect
          label="Please select role"
          options={roleOptions.map((x) => ({
            name: x,
            id: x.replace(" ", "_").toLocaleLowerCase(),
          }))}
          loading={loading}
          setFn={setRole}
          disabled={loading}
          defaultValue="user"
        />
      </>
    );
  }

  if (isMission) {
    content = (
      <>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={fieldLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <Flex width="100%">
          <Flex width="22%" direction="column">
            <Text marginBottom={-5}>
              From&nbsp;
              <Text
                as="span"
                fontSize="0.8rem"
                color="#dac57a"
                fontWeight="800"
              >
                (required)
              </Text>
            </Text>
            <input
              className="datetimePicker"
              type="date"
              id="start"
              name="date_start"
              value={startDate}
              onChange={(e) => onDatesChange(e.target.value, "start")}
            />
          </Flex>
          <Flex width="22%" direction="column">
            <Text marginBottom={-5}>
              To&nbsp;
              <Text
                as="span"
                fontSize="0.8rem"
                color="#dac57a"
                fontWeight="800"
              >
                (required)
              </Text>
            </Text>
            <input
              className="datetimePicker"
              type="date"
              id="end"
              name="date_end"
              value={endDate}
              onChange={(e) => onDatesChange(e.target.value, "end")}
            />
          </Flex>
        </Flex>
        <SearchSelect
          defaultValue={boatId}
          label="Please select boat"
          options={boats}
          loading={loading}
          setFn={setBoatId}
        />
      </>
    );
  }

  const t = `Update ${title}`;

  return (
    <>
      <Header title={t} />
      <Flex
        direction={{ base: "column", large: "row" }}
        alignItems="stretch"
        gap={tokens.space.xl}
        minHeight={isUser ? "50vh" : "15vh"}
      >
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "70%" }}
          padding="1rem"
          minHeight={isUser ? "auto" : "15vh"}
        >
          <Flex direction="column" width="100%">
            {content}
          </Flex>
        </View>
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "30%" }}
          padding="1rem"
          minHeight="15vh"
        >
          <FormActions
            text="Update"
            formOnSubmit={onSubmit}
            isLoading={loading}
            isDisabled={isDisabledCheck}
          />
        </View>
      </Flex>
    </>
  );
};

export default UpdateForm;

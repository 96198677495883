import React, { useState } from "react";
import moment from "moment";

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { LogsTableProps } from "@/types";
import {
  MdErrorOutline,
  MdOutlineDone,
  MdMediation,
  MdUploadFile,
  MdOutlinePending,
} from "react-icons/md";
import { defineSortIcon } from "../../../utils";

import "./index.css";

const defineEventType = (x) =>
  x === "upload"
    ? "Uploaded on"
    : x === "processing-start"
    ? "Processing started on"
    : "Processing finished on";

const defineIcon = (x) => {
  const icon =
    x === "pending" ? (
      <MdOutlinePending size="25px" style={{ margin: "0 auto" }} />
    ) : x === "upload" ? (
      <MdUploadFile size="25px" style={{ margin: "0 auto" }} />
    ) : (
      <MdMediation size="25px" style={{ margin: "0 auto" }} />
    );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {icon}
      <p
        style={{
          margin: "0 auto",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {x}
      </p>
    </div>
  );
};

const defineStatus = (x, logs) => {
  const icon = x ? (
    <MdOutlineDone size="25px" color="green" style={{ margin: "0 auto" }} />
  ) : (
    <MdErrorOutline size="25px" color="red" style={{ margin: "0 auto" }} />
  );
  let message = null;
  if (!x) {
    const e = logs.find((x) => x.event_type === "processing-error");
    message = (
      <p
        style={{
          margin: "0 auto",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {e?.event_message}
      </p>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {icon}
      {message}
    </div>
  );
};

const LogsTable = (props: LogsTableProps) => {
  const { logs } = props;

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...logs].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return logs;
  }, [logs, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const content = sortedData?.map((x) => {
    return (
      <TableRow key={x.id}>
        <TableCell style={{ width: "10%" }}>{x.filename || "/"}</TableCell>
        <TableCell style={{ width: "15%" }}>{x.mission_name || "/"}</TableCell>
        <TableCell>{x.boat_name || "/"}</TableCell>
        <TableCell>{new Date(x.created_at).toLocaleString()}</TableCell>
        <TableCell>{defineIcon(x.event)}</TableCell>
        <TableCell style={{ width: "12%" }}>
          {defineStatus(x.event_ok, x.log_history)}
        </TableCell>
        <TableCell className="history">
          <b>By: </b>
          <p className="history-by">{`${x.name} (${x.email})`}</p>
          {x.log_history && x.log_history.length
            ? x.log_history
                .sort(
                  (a, b) => +new Date(a.event_time) - +new Date(b.event_time)
                )
                .map((y) => (
                  <div key={y.event_id}>
                    <b className="history-bold">{`${defineEventType(y.event_type)}: `}</b>
                    {moment(y.event_time).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                ))
            : "/"}
        </TableCell>
      </TableRow>
    );
  });

  const header = (
    <TableRow>
      <TableCell
        style={{ width: "10%" }}
        as="th"
        onClick={() => requestSort("filename")}
        className="hh hhb"
      >
        Filename
        {defineSortIcon(sortConfig.key, sortConfig.direction, "filename")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("mission_name")}
        className="hh hhb"
        style={{ width: "15%" }}
      >
        Expedition
        {defineSortIcon(sortConfig.key, sortConfig.direction, "mission_name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("boat_name")}
        className="hh hhb"
      >
        Boat
        {defineSortIcon(sortConfig.key, sortConfig.direction, "boat_name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("created_at")}
        className="hh hhb"
      >
        Created at
        {defineSortIcon(sortConfig.key, sortConfig.direction, "created_at")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("event")}
        className="hh hhb"
      >
        Event
        {defineSortIcon(sortConfig.key, sortConfig.direction, "event")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("event_ok")}
        className="hh hhb"
        style={{ textAlign: "center", width: "12%" }}
      >
        Status
        {defineSortIcon(sortConfig.key, sortConfig.direction, "event_ok")}
      </TableCell>
      <TableCell as="th" className="hh">
        History
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Table caption="">
        <TableHead>{header}</TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </>
  );
};

export default LogsTable;

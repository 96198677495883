import React, { useEffect, useState } from "react";

import Config from "../../config";
import Header from "../tables/Header";
import FormActions from "./FormActions";
import Modal from "react-modal";
import QRsSteps from "./QRsSteps";
import SearchSelect from "../../components/SearchSelect";

import {
  Flex,
  Text,
  TextField,
  View,
  useTheme,
  Button,
  Badge,
} from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { modalStyles } from "./data";
import { Margin, usePDF } from "react-to-pdf";
import { makeCleanName } from "../../utils";

import "./AddForm.css";

const defineLabel = (label, required = true) => (
  <Text>
    {label}&nbsp;
    <Text
      as="span"
      fontSize="0.8rem"
      color={required ? "#dac57a" : "gray"}
      fontWeight="800"
    >
      {required === null ? "" : `(${required ? "required" : "optional"})`}
    </Text>
  </Text>
);

const AddForm = () => {
  const navigate = useNavigate();
  const {
    loading,
    isAdmin,
    isUser,
    user,
    agencies,
    boats,
    missions,
    locations,
    countries,
    users,
    getMissions,
    addAgency,
    addUser,
    addBoat,
    getAllAgencies,
    addMission,
    assignMissionToReporter,
    getBoats,
    addLocation,
    getLocations,
    getCountries,
    getAllUsers,
  } = useContx();

  const { tokens } = useTheme();

  const [agencyId, setAgencyId] = useState<string>(
    isUser ? user["agency_id"] : ""
  );
  const [role, setRole] = useState<string>(
    isUser ? Config.roles.pain_reporter : Config.roles.user
  );
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [locationId, setLocationId] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [boatId, setBoatId] = useState<string>("");
  const [missionId, setMissionId] = useState<string>("");
  const [reporterId, setReporterId] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmUrl, setConfirmUrl] = useState<string>("");

  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: "usepdf-example.pdf",
    page: { margin: Margin.SMALL },
  });
  const { state } = useLocation();

  const createUser = state === "User" || state === "Operator";
  const createMission = state === "Expedition";
  const createAgency = state === "Agency";
  const createBoat = state === "Boat";
  const createLocation = state === "Location";
  const assignMission =
    !!Object.keys(state).length && !!state?.role && !!state?.email;
  const assignReporter =
    !!Object.keys(state).length &&
    (!!state?.users || !!state?.boat_name || !!state?.date_start);

  const roleOptions = Object.values(Config.roles).map((x) => makeCleanName(x));

  useEffect(() => {
    if (createUser && isAdmin) {
      getAllAgencies();
    }
    if (createMission && isUser) {
      getBoats();
    }
    if (assignMission && isUser) {
      getMissions();
    }
    if (createBoat && isUser) {
      getLocations();
    }
    if (createLocation && isUser) {
      getCountries();
    }
    if (assignReporter && isUser) {
      getAllUsers();
    }
    //eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    try {
      if (createAgency) {
        const response = await addAgency(name);
        if (response?.success) {
          setName("");
          navigate(Config.routes.agencies);
        }
      }
      if (createUser) {
        const response = await addUser({
          agency_id: +agencyId,
          name,
          email,
          role,
        });
        if (response?.success) {
          setName("");
          setEmail("");
          setAgencyId("");
          if (role !== Config.roles.pain_reporter) {
            navigate(Config.routes.users);
          }
          if (role === Config.roles.pain_reporter) {
            setConfirmUrl(response["data"]["confirm_url"]);
            setIsOpen(true);
          }
        }
      }
      if (createBoat) {
        const response = await addBoat({
          name,
          model,
          location_id: locationId,
        });
        if (response?.success) {
          setName("");
          setCountryCode("");
          setModel("");
          navigate(Config.routes.boats);
        }
      }
      if (createMission) {
        const date_start = `${startDate} 00:00:00`;
        const date_end = `${endDate} 23:59:59`;
        const response = await addMission({
          name,
          date_start,
          date_end,
          boat: { id: boatId },
        });
        if (response?.success) {
          setName("");
          setStartDate("");
          setEndDate("");
          setBoatId("");
          navigate(Config.routes.missions);
        }
      }
      if (assignMission || assignReporter) {
        const response = await assignMissionToReporter({
          user_id: reporterId || state.id,
          mission_id: missionId || state.id,
        });
        if (response?.success) {
          setMissionId("");
          navigate(Config.routes.reporters);
        }
      }
      if (createLocation) {
        const response = await addLocation({ country_code: countryCode, name });
        if (response?.success) {
          setName("");
          setCountryCode("");
          navigate(Config.routes.boats);
        }
      }
    } catch (e) {
      throw e;
    }
  };

  const onDatesChange = (date, period) => {
    const val = date.split("-")[0]?.length;
    if (val >= 5) return;
    if (period === "start") {
      setStartDate(date);
    }
    if (period === "end") {
      setEndDate(date);
    }
  };

  let isDisabledCheck = false;

  if (loading || createUser) {
    if (role !== Config.roles.user) {
      isDisabledCheck = !agencyId || !name || !email || !role;
    }
    if (role === Config.roles.pain_reporter) {
      isDisabledCheck = !name || !role || !agencyId;
    }
    if (role !== Config.roles.user && role !== Config.roles.pain_reporter) {
      isDisabledCheck = !name || !email || !role;
    }
  }

  if (loading || createAgency) {
    isDisabledCheck = !name;
  }

  if (loading || createMission) {
    isDisabledCheck = !name || !startDate || !endDate || !boatId;
  }

  if (loading || createBoat) {
    isDisabledCheck = !name || !locationId || !model;
  }

  if (loading || createLocation) {
    isDisabledCheck = !name || !countryCode;
  }

  if (loading || assignMission) {
    isDisabledCheck = !missionId;
  }

  if (loading || assignReporter) {
    isDisabledCheck = !reporterId;
  }

  let content = null;

  if (createAgency) {
    content = (
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={defineLabel("Name")}
        type="text"
        isRequired={true}
        isDisabled={loading}
      />
    );
  }

  const agenciesOptions = isUser
    ? [{ id: user["agency_id"], name: user["agency_name"] }]
    : agencies;

  if (createUser) {
    content = (
      <>
        <SearchSelect
          label="Please select role"
          options={roleOptions.map((x) => ({
            name: x.replace("_", " "),
            id: x.replace(" ", "_").toLocaleLowerCase(),
          }))}
          loading={loading}
          setFn={setRole}
          disabled={loading || isUser}
          defaultValue={role}
        />
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={defineLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label={defineLabel("Email", role !== Config.roles.pain_reporter)}
          type="text"
          isRequired={role !== Config.roles.pain_reporter}
          isDisabled={loading}
        />
        <SearchSelect
          label="Please select agency"
          options={agenciesOptions}
          loading={loading}
          setFn={setAgencyId}
          required={
            role !== Config.roles.user && role !== Config.roles.pain_reporter
              ? null
              : true
          }
          isRequired={
            role === Config.roles.user || role === Config.roles.pain_reporter
          }
          disabled={
            loading ||
            isUser ||
            (role !== Config.roles.user && role !== Config.roles.pain_reporter)
          }
          defaultValue={agencyId}
          customKey={role}
        />
      </>
    );
  }

  if (createMission) {
    content = (
      <>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={defineLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <Flex width="100%">
          <Flex width="22%" direction="column">
            <Text marginBottom={-5}>
              From&nbsp;
              <Text
                as="span"
                fontSize="0.8rem"
                color="#dac57a"
                fontWeight="800"
              >
                (required)
              </Text>
            </Text>
            <input
              className="datetimePicker"
              type="date"
              id="start"
              name="date_start"
              value={startDate}
              onChange={(e) => onDatesChange(e.target.value, "start")}
            />
          </Flex>
          <Flex width="22%" direction="column">
            <Text marginBottom={-5}>
              To&nbsp;
              <Text
                as="span"
                fontSize="0.8rem"
                color="#dac57a"
                fontWeight="800"
              >
                (required)
              </Text>
            </Text>
            <input
              className="datetimePicker"
              type="date"
              id="end"
              name="date_end"
              value={endDate}
              onChange={(e) => onDatesChange(e.target.value, "end")}
            />
          </Flex>
        </Flex>
        <SearchSelect
          label="Please select boat"
          options={boats}
          loading={loading}
          setFn={setBoatId}
        />
      </>
    );
  }

  if (createBoat) {
    content = (
      <>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={defineLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <TextField
          value={model}
          onChange={(e) => setModel(e.target.value)}
          label={defineLabel("Model")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <SearchSelect
          label="Please select location"
          options={locations}
          loading={loading}
          setFn={setLocationId}
        />
      </>
    );
  }

  if (assignMission) {
    const alreadyAssignedMissions = missions.filter((x) =>
      x.users.map((y) => y.id).includes(state.id)
    );
    const alreadyAssignedMissionsIds = alreadyAssignedMissions.map((x) => x.id);
    content = (
      <>
        <SearchSelect
          label="Please select expedition"
          options={missions}
          loading={loading}
          disabledArrCheck={alreadyAssignedMissionsIds}
          setFn={setMissionId}
        />
        <Flex alignItems="center">
          <Text>Already assigned expeditions :</Text>
          <Flex alignItems="center">
            {alreadyAssignedMissions.length
              ? alreadyAssignedMissions.map((z, i) => (
                  <Badge key={i} border="1px solid #dac57a">
                    {z.name}
                  </Badge>
                ))
              : "None."}
          </Flex>
        </Flex>
      </>
    );
  }

  if (assignReporter) {
    const alreadyASsignedReportersIds = state.users.map((x) => x.id);
    content = (
      <>
        <SearchSelect
          label="Please select expedition"
          options={users}
          loading={loading}
          disabledArrCheck={alreadyASsignedReportersIds}
          setFn={setReporterId}
        />
        <Flex alignItems="center">
          <Text>Already assigned operators :</Text>
          <Flex alignItems="center">
            {state.users.length && state.users[0]?.id !== null
              ? state.users.map((z, i) => (
                  <Badge key={i} border="1px solid #dac57a">
                    {z.name}
                  </Badge>
                ))
              : "None."}
          </Flex>
        </Flex>
      </>
    );
  }

  if (createLocation) {
    content = (
      <>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={defineLabel("Name")}
          type="text"
          isRequired={true}
          isDisabled={loading}
        />
        <SearchSelect
          label="Country Code"
          options={countries}
          loading={loading}
          setFn={setCountryCode}
        />
      </>
    );
  }

  let mds = { ...modalStyles };

  if (window.innerWidth < 1100) {
    mds.content.width = "85vw";
  }

  const modal = createUser ? (
    <Modal
      ariaHideApp={false}
      contentLabel="Example Modal"
      style={mds}
      isOpen={isOpen}
    >
      <Button
        marginBottom={10}
        style={{ marginLeft: "96%", marginTop: "0", marginBottom: "0%" }}
        onClick={() => setIsOpen(false)}
      >
        X
      </Button>
      <div ref={targetRef}>
        <QRsSteps confirmUrl={confirmUrl} />
      </div>
      <Button
        width={{ base: "50%", large: "50%" }}
        style={{ marginTop: "5%", marginLeft: "25%" }}
        onClick={() => toPDF()}
      >
        Print
      </Button>
    </Modal>
  ) : (
    <></>
  );

  let title = `Add ${state}`;

  if (assignMission) {
    title = `Assign expedition to (${state.name})`;
  }

  if (assignReporter) {
    title = `Assign operator to (${state.name})`;
  }

  return (
    <>
      {modal}
      <Header title={title} />
      <Flex
        direction={{ base: "column", large: "row" }}
        alignItems="flex-start"
        gap={tokens.space.xl}
        minHeight={createUser ? "45vh" : "15vh"}
      >
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "70%" }}
          padding="1rem"
          minHeight="15vh"
        >
          <Flex direction="column" width="100%">
            {content}
          </Flex>
        </View>
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          width={{ base: "100%", large: "30%" }}
          padding="1rem"
          minHeight="15vh"
        >
          <FormActions
            text={assignMission ? "Assign" : "Create"}
            formOnSubmit={onSubmit}
            isLoading={loading}
            isDisabled={isDisabledCheck}
          />
        </View>
      </Flex>
    </>
  );
};

export default AddForm;

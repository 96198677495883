import React, { useState } from "react";
import Config from "../../../config";
import moment from "moment";
import { FiMoreHorizontal } from "react-icons/fi";

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SwitchField,
  Menu,
  MenuItem,
  MenuButton,
} from "@aws-amplify/ui-react";
import { UsersTableProps } from "@/types";
import { makeCleanName } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { defineSortIcon } from "../../../utils";
import { useContx } from "../../../AuthProvider";

import "./index.css";

const UsersTable = (props: UsersTableProps) => {
  const { users } = props;
  const { silentLoading, updateUserStatus } = useContx();

  const location = useLocation();
  const navigate = useNavigate();

  const isReportersScreen = location?.pathname.includes(
    Config.routes.reporters
  );

  const isUsersScreen = location?.pathname.includes(Config.routes.users);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...users].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return users;
  }, [users, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const header = (
    <TableRow>
      <TableCell as="th" onClick={() => requestSort("name")} className="hh hhb">
        Name
        {defineSortIcon(sortConfig.key, sortConfig.direction, "name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("email")}
        className="hh hhb"
      >
        Email
        {defineSortIcon(sortConfig.key, sortConfig.direction, "email")}
      </TableCell>
      {isUsersScreen && (
        <>
          <TableCell
            as="th"
            onClick={() => requestSort("agency_name")}
            className="hh hhb"
          >
            Agency
            {defineSortIcon(
              sortConfig.key,
              sortConfig.direction,
              "agency_name"
            )}
          </TableCell>
          <TableCell
            as="th"
            onClick={() => requestSort("role")}
            className="hh hhb"
          >
            Role
            {defineSortIcon(sortConfig.key, sortConfig.direction, "role")}
          </TableCell>
        </>
      )}
      {isReportersScreen && (
        <TableCell
          as="th"
          onClick={() => requestSort("last_answer")}
          className="hh hhb"
          style={{width: '15%'}}
        >
          Last Answer
          {defineSortIcon(sortConfig.key, sortConfig.direction, "last_answer")}
        </TableCell>
      )}
      {isReportersScreen && (
        <TableCell
          as="th"
          onClick={() => requestSort("last_pain_log")}
          className="hh hhb"
          style={{width: '15%'}}
        >
          Last Pain Log
          {defineSortIcon(
            sortConfig.key,
            sortConfig.direction,
            "last_pain_log"
          )}
        </TableCell>
      )}
      <TableCell
        as="th"
        className="hh hhb"
        onClick={() => requestSort("active")}
        style={{width: '7%'}}
      >
        Status
        {defineSortIcon(sortConfig.key, sortConfig.direction, "active")}
      </TableCell>
      {isReportersScreen && (
        <TableCell as="th" className="hh">
          Actions
        </TableCell>
      )}
    </TableRow>
  );

  const content = sortedData?.map((x) => {
    return (
      <TableRow key={x.id}>
        <TableCell>{x.name}</TableCell>
        <TableCell>{x.email}</TableCell>
        {isUsersScreen && (
          <>
            <TableCell>{x.agency_name}</TableCell>
            <TableCell>{makeCleanName(x.role)}</TableCell>
          </>
        )}
        {isReportersScreen && (
          <TableCell>
            {x.last_answer
              ? moment(x.last_answer).format("YYYY-MM-DD HH:mm:ss")
              : "/"}
          </TableCell>
        )}
        {isReportersScreen && (
          <TableCell>
            {x.last_pain_log
              ? moment(x.last_pain_log).format("YYYY-MM-DD HH:mm:ss")
              : "/"}
          </TableCell>
        )}
        <TableCell>
          <SwitchField
            className="switcher"
            trackCheckedColor="green"
            trackColor="red"
            marginRight={7}
            isDisabled={silentLoading}
            defaultChecked={x.active}
            label=""
            labelPosition="start"
            onChange={(e) => {
              e.stopPropagation();
              updateUserStatus({ id: x.id, active: e.target.checked });
            }}
          />
        </TableCell>
        {isReportersScreen && (
          <TableCell>
            <Menu
              trigger={
                <MenuButton
                  variation="menu"
                  border="1px solid lightgray"
                  size="large"
                  width="100%"
                  justifyContent="center"
                >
                  <FiMoreHorizontal />
                </MenuButton>
              }
            >
              <MenuItem
                onClick={() =>
                  navigate(Config.routes.reporterLogs, {
                    state: { id: x.id, name: x.name },
                  })
                }
              >
                Pain Logs
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(Config.routes.assignToAMission, { state: x })
                }
              >
                Assign Expedition
              </MenuItem>
            </Menu>
          </TableCell>
        )}
      </TableRow>
    );
  });

  return (
    <>
      <Table caption="">
        <TableHead>{header}</TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </>
  );
};

export default UsersTable;

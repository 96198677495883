import React, { useState, useEffect } from "react";

import Config from "../../config";
import SideBarNav from "./SidebarNav";
import SidebarNavToggle from "./SidebarNavToggle";
import { useLocation } from "react-router-dom";
import { useContx } from "../../AuthProvider";

import "./index.css";

const SideBar = () => {
  const { isAdmin, isUser } = useContx();
  const [expand, setExpand] = useState(false);

  let location = useLocation();

  const navs = Config.appNavs.filter(
    (x) =>
      x.role === "all" ||
      x.role ===
        (isAdmin
          ? Config.roles.admin
          : isUser
          ? Config.roles.user
          : Config.roles.researcher)
  );

  useEffect(() => {
    setExpand(false);
  }, [location]);

  return (
    <>
      <div className="btn-sidebar-nav">
        <SidebarNavToggle expand={expand} onChange={() => setExpand(!expand)} />
      </div>
      {(isAdmin || isUser) && (
        <div className={"sidebar " + (expand ? "visible" : "")}>
          <SideBarNav navs={navs} />
        </div>
      )}
    </>
  );
};

export default SideBar;

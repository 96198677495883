import React, { useEffect } from "react";

import Config from "../../config";
import UsersTable from "./UsersTable";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import Wrapper from "../../components/Wrapper";
import CustomButton from "../../components/CustomButton";
import Pagination from "../../components/Pagination";

import { ScrollView, Flex } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";

const UsersTablePage = () => {
  const {
    isAdmin,
    isUser,
    users,
    getUsers,
    error,
    loading,
    pagination,
    totals,
  } = useContx();

  useEffect(() => {
    (isAdmin || isUser) && getUsers(1);
    //eslint-disable-next-line
  }, []);

  let u = users;

  u = users.slice(
    (pagination?.users?.currentPage - 1) * pagination?.perPage,
    pagination?.users?.currentPage * pagination?.perPage
  );

  let content = (
    <>
      <Flex alignItems="center" padding={10}>
        <CustomButton
          path={Config.routes.addUser}
          state={isUser ? "Operator" : "User"}
          check={isAdmin || isUser}
        />
      </Flex>
      <Wrapper>
        <br></br>
        <ScrollView width="100%">
          <UsersTable users={u} />
          <Pagination
            fn={getUsers}
            cp={pagination?.users?.currentPage}
            pp={pagination?.perPage}
            t={totals?.users?.total}
            v={totals?.users?.visited}
          />
        </ScrollView>
      </Wrapper>
    </>
  );

  if (loading) {
    content = <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return content;
};

export default UsersTablePage;

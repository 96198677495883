import React from "react";

import { Flex } from "@aws-amplify/ui-react";

import "./index.css";

const Spinner = () => (
  <Flex alignItems="center" justifyContent="center">
    <span className="loader"></span>
  </Flex>
);

export default Spinner;

import React, { useEffect } from "react";
import Spinner from "../../../components/Spinner";
import Error from "../../../components/Error";
import Wrapper from "../../../components/Wrapper";
import moment from "moment";
import Info from "./Info";

import { useLocation } from "react-router-dom";
import { useContx } from "../../../AuthProvider";
import { Table } from "antd";
import { Flex, ScrollView } from "@aws-amplify/ui-react";

import { columns } from "./data";

import "./index.css";

const UserLogs = () => {
  const { getUserLogs, isUser, userLogs, loading, error } = useContx();
  const { state } = useLocation();

  let content = null;

  const data = userLogs.map((x, i) => ({
    key: i,
    logType: x.log_type,
    date: moment(x.date).format("YYYY-MM-DD HH:mm:ss"),
    log: x,
  }));

  useEffect(() => {
    if (isUser) {
      getUserLogs(state.id);
    }
    //eslint-disable-next-line
  }, []);

  content = (
    <>
      <Flex alignItems="center" padding={2}>
        <p className="title">
          Pain logs from: <b>{state.name}</b>
        </p>
      </Flex>
      <Wrapper>
        <br></br>
        <ScrollView width="100%">
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (record) => <Info log={record.log} />,
            }}
            dataSource={data}
            rowHoverable={false}
          />
        </ScrollView>
      </Wrapper>
    </>
  );
  
  if (loading) {
    content = <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }
  
  return content;
};

export default UserLogs;

import React from "react";

import { NavLink } from "react-router-dom";
import { Icon } from "@aws-amplify/ui-react";
import { SidebarNavProps } from "@/types";

const SideBarNav = (props: SidebarNavProps) => {
  const { navs } = props;

  let activeClassName = "active";

  return (
    <div className="sidebar-nav">
      <ul>
        {navs?.map((x) => {
          const { children, ...rest } = x;

          if (children) {
            return (
              <li key={x.eventKey}>
                <NavLink
                  to={x.to}
                  className={({ isActive }) =>
                    (isActive && !x.external)? activeClassName : undefined
                  }
                >
                  <Icon>{x.icon}</Icon>
                  {x.title}
                </NavLink>
                <ul>
                  {children.map((c) => {
                    return (
                      <li key={c.eventKey}>
                        <NavLink
                          to={c.to}
                          className={({ isActive }) =>
                            (isActive && !x.external)? activeClassName : undefined
                          }
                        >
                          {c.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }

          if (rest.target === "_blank") {
            return <></>;
          }

          return (
            <li key={x.eventKey}>
              <NavLink
                to={x.to}
                className={({ isActive }) =>
                  (isActive && !x.external)? activeClassName : undefined
                }
              >
                <Icon>{x.icon}</Icon>
                {x.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideBarNav;

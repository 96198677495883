import React, { useCallback, useMemo } from "react";

import { Text, View } from "@aws-amplify/ui-react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  display: "flex",
  padding: "15px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ReactDropzoneCSV = ({ onChange, disabled, file }: any) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange && onChange(acceptedFiles);
    },
    [onChange]
  );

  // const sizePerTypeValidator = (file) => {
  //   const type = file.type.split("/")[0];
  // };

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    // validator: sizePerTypeValidator,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const f = file ? (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ) : null;
  
  const fileRejectionItems = fileRejections.map(({ x, errors }: any) => (
    <li key={x.path}>
      {x.path} - {x.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <View>
      <Text>
        File (ZIP)&nbsp;
        <Text as="span" fontSize="0.8rem" color="#dac57a" fontWeight="800">
          (required)
        </Text>
      </Text>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} disabled={disabled} />
        Drag 'n' drop your file here, or click to select file
      </div>
      <div style={{ marginTop: "15px" }}>{f}</div>
      {!!fileRejectionItems.length && (
        <div color="red">{fileRejectionItems}</div>
      )}
    </View>
  );
};

export default ReactDropzoneCSV;

import React, { useEffect } from "react";

import Agencies from "../tables/AgenciesTablePage";
import Logs from "../tables/LogsTablePage";
// import Dashboard from "../dashboard";
import { useContx } from "../../AuthProvider";

const Landing = () => {
  const { isAdmin, isUser, isResearcher } = useContx();

  useEffect(() => {
    if (isResearcher) {
      window.location.href = process.env.REACT_APP_SUPERSET_ENDPOINT
    }
  }, [isResearcher]);

  let component = <></>;

  if (isAdmin) {
    component = <Agencies />;
  }

  if (isUser) {
    component = <Logs />;
  }

  if (isResearcher) {
    // component = <Dashboard />;
    component = <></>
  }

  return component;
};

export default Landing;

import React from "react";

import { Button } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { CustomButtonProps } from "@/types";

const CustomButton = (props: CustomButtonProps) => {
  const { path, state, check, margin } = props;
  const navigate = useNavigate();

  if (!check) {
    return null;
  }

  let m = margin || 'auto'

  return (
    <Button
      width={{ base: "50%", large: "10%" }}
      style={{ marginLeft: m, height: "5%" }}
      onClick={() => navigate(path, { state })}
    >
      +{state}
    </Button>
  );
};

export default CustomButton;

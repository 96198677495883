import React from "react";

import { Loader } from "@aws-amplify/ui-react";

const Loading = ({ percentage }: any) => {
  return (
    <Loader
      style={{ width: "95%", margin: "5px 7px", marginLeft: '10px' }}
      variation="linear"
      percentage={percentage}
      isDeterminate
    />
  );
};

export default Loading;

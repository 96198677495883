import React, { useState } from "react";

import { Flex, Text, TextField } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";
import FormActions from "../forms/FormActions";

const defineLabel = (label) => (
  <Text>
    {label}&nbsp;
    <Text as="span" fontSize="0.8rem" color="#dac57a" fontWeight="800">
      (required)
    </Text>
  </Text>
);

const ChangePassword = ({ setChangePw }) => {
  const { loading, changePassword } = useContx();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const onSubmit = async () => {
    const success: boolean = await changePassword({
      oldPassword,
      newPassword,
      confirmPassword,
    });
    if (success) {
      setChangePw(null);
    }
  };

  const isDisabledCheck =
    loading || !oldPassword || !newPassword || !confirmPassword;

  return (
    <Flex direction="column" width="100%">
      <TextField
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        label={defineLabel("Old Password")}
        type="password"
        isRequired={true}
        isDisabled={loading}
      />
      <TextField
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        label={defineLabel("New Password")}
        type="password"
        isRequired={true}
        isDisabled={loading}
      />
      <TextField
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        label={defineLabel("Confirm Password")}
        type="password"
        isRequired={true}
        isDisabled={loading}
      />
      <FormActions
        text="Save"
        formOnSubmit={onSubmit}
        isLoading={loading}
        isDisabled={isDisabledCheck}
      />
    </Flex>
  );
};

export default ChangePassword;

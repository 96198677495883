import React, { useState } from "react";
import { Pagination as MyPagination } from "@aws-amplify/ui-react";
import { PaginationProps } from "@/types";
import "./index.css";

const Pagination = (props: PaginationProps) => {
  const { fn, cp, pp, t, v } = props;
  const [currentPageIndex, setCurrentPageIndex] = useState(cp);
  const totalPages = Math.ceil(t / pp);

  const handleNextPage = () => {
    const cp = currentPageIndex + 1;
    setCurrentPageIndex(cp);
    fn && fn(+cp);
  };

  const handlePreviousPage = () => {
    const cp = currentPageIndex - 1;
    setCurrentPageIndex(cp);
    fn && fn(+cp);
  };

  const handleOnChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex);
    fn && fn(+newPageIndex);
  };

  return (
    <div className="pagination">
      <MyPagination
        hasMorePages={totalPages < cp}
        currentPage={currentPageIndex}
        totalPages={v > totalPages ? totalPages : v}
        onNext={handleNextPage}
        onPrevious={handlePreviousPage}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default Pagination;

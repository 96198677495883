import React, { useEffect } from "react";

import Config from "../../config";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import Wrapper from "../../components/Wrapper";
import CustomButton from "../../components/CustomButton"
import MissionsTable from "./MissionsTable";

import { ScrollView, Flex } from "@aws-amplify/ui-react";
import { useContx } from "../../AuthProvider";

const MissionsTablePage = () => {
  const { isUser, missions, getMissions, loading, error } = useContx();

  useEffect(() => {
    isUser && getMissions();
    //eslint-disable-next-line
  }, []);

  let content = (
    <>
      <Flex alignItems="center" padding={10}>
        <CustomButton
          path={Config.routes.addMission}
          state="Expedition"
          check={isUser}
        />
      </Flex>
      <Wrapper>
        <br></br>
        <ScrollView width="100%">
          <MissionsTable missions={missions} />
        </ScrollView>
      </Wrapper>
    </>
  );

  if (loading) {
    content = <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return content;
};

export default MissionsTablePage;

import React from "react";
import Config from "./config";
import { toast } from "react-toastify";
import { Icon } from "@aws-amplify/ui-react";
import { MdArrowDownward, MdArrowUpward, MdImportExport } from "react-icons/md";

export const notifySuccess = (message: string) =>
  toast.success(message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const notifyError = (message: string) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const makeCleanName = (name: string) => {
  if (name === Config.roles.pain_reporter) {
    const [p1, p2] = name.split("_");
    return `${p1.charAt(0).toUpperCase()}${p1.slice(1)} ${p2
      .charAt(0)
      .toUpperCase()}${p2.slice(1)}`;
  }
  return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};

export const defineSortIcon = (
  key: string,
  direction: string,
  header: string
) => {
  if (!key || key !== header) {
    return (
      <Icon
        as={MdImportExport}
        style={{ marginLeft: "3px", marginBottom: "2px" }}
      />
    );
  }
  if (key === header && direction === "ascending") {
    return (
      <Icon
        as={MdArrowUpward}
        style={{ marginLeft: "3px", marginBottom: "2px" }}
      />
    );
  }
  if (key === header && direction === "descending") {
    return (
      <Icon
        as={MdArrowDownward}
        style={{ marginLeft: "3px", marginBottom: "2px" }}
      />
    );
  }
};

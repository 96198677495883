import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Config from "./config";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthProvider";
import { Authenticator, Heading, View, useTheme } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "reactjs-popup/dist/index.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div className="dot"></div>
        <Heading
          fontSize="40px"
          color="#dac57a"
          backgroundColor="#173660"
          width="fit-content"
          margin="10px auto"
          marginTop="4px"
          padding="1px 5px"
          borderRadius="5px"
        >
          {Config.headerText}
        </Heading>
      </View>
    );
  },
};

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Authenticator
        components={components}
        signUpAttributes={["email"]}
        loginMechanisms={["email"]}
      >
        <App />
      </Authenticator>
    </AuthProvider>
  </BrowserRouter>
);

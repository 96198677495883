import React, { useState } from "react";

import ProfileInformation from "./ProfileInformation";
import ChangePassword from "./ChangePassword";

import { View, Flex, useTheme } from "@aws-amplify/ui-react";

import "./index.css";

const Profile = () => {
  const { tokens } = useTheme();
  const [changePw, setChangePw] = useState<boolean>(false);

  return (
    <>
      <div>
        <h2>Profile</h2>
      </div>
      <View maxWidth="100%" padding="0rem" minHeight="100vh">
        <Flex
          direction={{ base: "row", large: "row" }}
          alignItems="stretch"
          gap={tokens.space.xl}
        >
          <View
            backgroundColor="var(--amplify-colors-white)"
            borderRadius="6px"
            width={{ base: "48.5%", large: "48.5%" }}
            padding="1rem"
          >
            <ProfileInformation changePw={changePw} setChangePw={setChangePw} />
          </View>
          {changePw && (
            <View
              backgroundColor="var(--amplify-colors-white)"
              borderRadius="6px"
              width={{ base: "48.5%", large: "48.5%" }}
              padding="1rem"
            >
              <ChangePassword setChangePw={setChangePw} />
            </View>
          )}
        </Flex>
      </View>
    </>
  );
};

export default Profile;

import React from "react";
import moment from "moment";

import { InfoProps } from "@/types";
import { Image, Table } from "antd";
import { boatColumns, answerColumns, painColumns } from "./data";
import { Badge } from "@aws-amplify/ui-react";

const Info = (props: InfoProps) => {
  const isBoatLog = props.log.log_type === "boat_log";
  const isAnswerLog = props.log.log_type === "answer_log";
  const isPainLog = props.log.log_type === "pain_log";
  let content = null;
  let data = [];
  if (isBoatLog) {
    if (props.log.details.boat && Object.keys(props.log.details.boat).length) {
      data = [
        {
          name: props.log.details.boat.name,
          createdAt: moment(props.log.details.boat.created_at).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          model: props.log.details.boat.model,
          attributes: props.log.details.boat.attributes,
        },
      ];
      content = (
        <Table
          key="boat_table"
          dataSource={data}
          columns={boatColumns}
          pagination={false}
        />
      );
    }
  }
  if (isAnswerLog) {
    if (props.log.details.answers && props.log.details.answers.length) {
      data = props.log.details.answers.map((x) => ({
        question: x.question,
        answer: x?.answer || "/",
        options:
          x?.options && x?.options.length ? (
            x.options.map((y, i) => (
              <Badge key={i} border="1px solid #dac57a" marginRight={5}>
                {y}
              </Badge>
            ))
          ) : (
            <></>
          ),
      }));
      content = (
        <Table key="answers_table" dataSource={data} columns={answerColumns} />
      );
    }
  }

  if (isPainLog) {
    if (
      props.log.details.body_part ||
      props.log.details.pain_log ||
      props.log.details.pain_type
    ) {
      data = [
        {
          image: <Image width={40} src={props.log.details?.body_part?.image_url}/>,
          name: props.log.details?.body_part?.name,
          bodyPart: props.log.details?.body_part?.type,
          painType: props.log.details?.pain_type?.name,
          painDescription: props.log.details?.pain_type?.description,
          intensity: props.log.details?.pain_log?.intensity,
          medication: props.log.details?.pain_log?.medication || '/',
        },
      ];
      content = (
        <Table key="answers_table" dataSource={data} columns={painColumns} />
      );
    }
  }
  return content;
};

export default Info;

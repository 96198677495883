import React, { useEffect } from "react";

import Config from "./config";
import Layout from "./components/Layout";
import Profile from "./pages/profile";
import Users from "./pages/tables/UsersTablePage";
import AddForm from "./pages/forms/AddForm";
import UpdateForm from "./pages/forms/UpdateForm";
import Landing from "./pages/landing";
import UploadForm from "./pages/forms/UploadForm";
import Missions from "./pages/tables/MissionsTablePage";
import Boats from "./pages/tables/BoatsTablePage";
import ReporterLogs from "./pages/tables/UserLogs";
import Dashboard from "./pages/dashboard";

import theme from "./theme";

import { Routes, Route, Link } from "react-router-dom";
import { ThemeProvider, useAuthenticator } from "@aws-amplify/ui-react";
import { ToastContainer } from "react-toastify";
import { useContx } from "./AuthProvider";

import "react-toastify/dist/ReactToastify.css";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

const makeRoute = (path: string) => path.replace("/", "");

const App = () => {
  const { isAdmin, isUser, initiateContx } = useContx();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    initiateContx();
    //eslint-disable-next-line
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route
              path={makeRoute(Config.routes.addUser)}
              element={<AddForm />}
            />
            {isUser && (
              <>
                <Route
                  path={makeRoute(Config.routes.uploadFile)}
                  element={<UploadForm />}
                />
                <Route
                  path={makeRoute(Config.routes.reporters)}
                  element={<Users />}
                />
                <Route
                  path={makeRoute(Config.routes.boats)}
                  element={<Boats />}
                />
                <Route
                  path={makeRoute(Config.routes.missions)}
                  element={<Missions />}
                />
                <Route
                  path={makeRoute(Config.routes.addBoat)}
                  element={<AddForm />}
                />
                <Route
                  path={makeRoute(Config.routes.addLocation)}
                  element={<AddForm />}
                />
                <Route
                  path={makeRoute(Config.routes.addMission)}
                  element={<AddForm />}
                />
                <Route
                  path={makeRoute(Config.routes.assignToAMission)}
                  element={<AddForm />}
                />
                <Route
                  path={makeRoute(Config.routes.reporterLogs)}
                  element={<ReporterLogs />}
                />
                <Route
                  path={makeRoute(Config.routes.updateBoat)}
                  element={<UpdateForm title="Boat" />}
                />
                <Route
                  path={makeRoute(Config.routes.updateMission)}
                  element={<UpdateForm title="Expedition" />}
                />
              </>
            )}
            {isAdmin && (
              <>
                <Route
                  path={makeRoute(Config.routes.addAgency)}
                  element={<AddForm />}
                />
                <Route
                  path={makeRoute(Config.routes.updateAgency)}
                  element={<UpdateForm title="Agency" />}
                />
                <Route
                  path={makeRoute(Config.routes.users)}
                  element={<Users />}
                />
              </>
            )}
            <Route
              path={makeRoute(Config.routes.profile)}
              element={<Profile />}
            />
            <Route
              path={makeRoute(Config.routes.dashboard)}
              element={<Dashboard />}
            />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
};

export default App;

const NoMatch = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

import React from "react";

import Config from "../../config";
import Logo from "../Logo";
import HeaderNav from "./HeaderNav";
import HeaderSearchBar from "./HeaderSearchBar";
import { Flex } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { HeaderProps } from "../../types";

import "./index.css";

const Header = (props: HeaderProps) => {
  return (
    <div className="header">
      <Flex
        direction="row"
        alignItems="center"
        wrap="nowrap"
        gap="1rem"
        justifyContent="space-between"
      >
        <div className="header-left">
          <div className="header-logo">
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo />
            </Link>
            <div className="dot2"></div>
          </div>
        </div>

        <div className="header-right">
          {Config.search ? <HeaderSearchBar /> : <></>}
          <HeaderNav agency={props.agency}/>
        </div>
      </Flex>
    </div>
  );
};

export default Header;

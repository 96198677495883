import React from "react";
import Config from "../../config";

import "./index.css";

const Footer = () => {
  return <div className="footer">{Config.footerText}</div>;
};

export default Footer;

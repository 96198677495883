import React from "react";
import QRCode from "react-qr-code";
import { Flex, useTheme, View } from "@aws-amplify/ui-react";
import { QrsProps } from "@/types";

const androidUrl =
  "https://play.google.com/store/apps/details?id=com.gy.mashien";
const iosUrl = "https://apps.apple.com/it/app/mashien-app/id6499588490?l=en-GB";

const QRsSteps = (props: QrsProps) => {
  const { confirmUrl } = props;
  const { tokens } = useTheme();
  return (
    <>
      <Flex
        direction={{ base: "row", large: "row" }}
        alignItems="stretch"
        gap={tokens.space.xl}
        wrap="wrap"
      >
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          border="1px solid lightgray"
          width={{ base: "100%", large: "100%" }}
          padding="0.5rem"
        >
          <Flex alignItems="center">
            <div style={{ width: "25%" }}>
              <h3>1. Download the app</h3>
            </div>
            <div
              style={{
                border: "1px solid lightgray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1%",
                width: "80%",
              }}
            >
              <Flex
                width={{ base: "49%", large: "49%" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <h4 style={{ margin: "0" }}>Android</h4>
                <QRCode value={androidUrl} size={150} />
              </Flex>
              <div
                style={{
                  width: "0.1%",
                  height: "245px",
                  backgroundColor: "lightGray",
                }}
              ></div>
              <Flex
                width={{ base: "49%", large: "49%" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <h4 style={{ margin: "0" }}>iOS</h4>
                <QRCode value={iosUrl} size={150} />
              </Flex>
            </div>
          </Flex>
        </View>
        <View
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          border="1px solid lightgray"
          width={{ base: "100%", large: "100%" }}
          padding="0.5rem"
          marginTop={-15}
        >
          <Flex alignItems="center">
            <div style={{ width: "25%" }}>
              <h3>2. Authentication</h3>
            </div>

            <div
              style={{
                border: "1px solid lightgray",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Flex
                width={{ base: "100%", large: "100%" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
                padding={20}
                paddingBottom={20}
                marginLeft="auto"
              >
                <h4 style={{ margin: "0" }}>User registration</h4>
                <QRCode value={confirmUrl} size={150} />
              </Flex>
            </div>
          </Flex>
        </View>
      </Flex>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        border="1px solid lightgray"
        width={{ base: "100%", large: "100%" }}
        padding="0.5rem"
        marginTop={17.5}
      >
        <h3 style={{ margin: "0px", marginBottom: "14px" }}>
          3. Start using the app Daily
        </h3>
        <p style={{ margin: "3px" }}>
          It is important for the success of the study that you log daily even
          is you have no pain. In fact you can log pain, selecting the type of
          ‘“no pain”, or a specific pain from the list.
        </p>
        <p style={{ margin: "3px" }}>
          - You should log also the presence on the boat for that day.{" "}
        </p>
        <p style={{ margin: "3px" }}>
          - The app also allow to log additional information
        </p>
      </View>
    </>
  );
};

export default QRsSteps;

import React, { useState, useContext, createContext } from "react";
import Config from "./config";

import { API, Amplify, Auth } from "aws-amplify";
import { notifyError, notifySuccess } from "./utils";
import { useNavigate } from "react-router-dom";
import {
  Agency,
  AssignMission,
  Boat,
  ChangePassword,
  Log,
  Mission,
  UploadFile,
  User,
  Location,
  Country,
  UserLog,
  Pagination,
  Totals,
} from "./types";

const initialState = {
  user: {},
  users: [],
  agencies: [],
  logs: [],
  missions: [],
  boats: [],
  locations: [],
  countries: [],
  userLogs: [],
  uploadProgress: 0,
  pagination: {
    perPage: 8,
    users: {
      currentPage: 0,
      visited: [],
    },
    agencies: {
      currentPage: 0,
      visited: [],
    },
    logs: {
      currentPage: 0,
      visited: [],
    },
  },
  totals: {
    users: {
      total: 0,
      visited: 1,
    },
    agencies: {
      total: 0,
      visited: 1,
    },
    logs: {
      total: 0,
      visited: 1,
    },
  },
  loading: false,
  silentLoading: false,
  isAdmin: false,
  isUser: false,
  isResearcher: false,
  error: null,
  initiateContx: () => {},
  getAgencies: async (_) => {},
  getAllAgencies: async () => {},
  addAgency: async (_): Promise<any> => {},
  updateAgency: async (_) => {},
  getUsers: async (_): Promise<any> => {},
  getAllUsers: async (): Promise<any> => {},
  addUser: async (_): Promise<any> => {},
  updateUserStatus: async (_): Promise<any> => {},
  uploadFile: async (_) => {},
  getLogs: async (_) => {},
  getMissions: async () => {},
  addMission: async (_): Promise<any> => {},
  updateMission: async (_): Promise<any> => {},
  getBoats: async () => {},
  addBoat: async (_): Promise<any> => {},
  updateBoat: async (_): Promise<any> => {},
  addLocation: async (_): Promise<any> => {},
  getLocations: async () => {},
  getUserLogs: async (_) => {},
  getCountries: async () => {},
  assignMissionToReporter: async (_): Promise<any> => {},
  changePassword: async (_): Promise<any> => {},
  logout: async () => {},
};

const authContext = createContext(initialState);

export function AuthProvider({ children }) {
  const awsConfig = {
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_API_NAME,
          endpoint: process.env.REACT_APP_API_ENDPOINT,
        },
      ],
    },
  };

  Amplify.configure(awsConfig);

  const value = useMyContext();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}

export const useContx = () => {
  return useContext(authContext);
};

const useMyContext = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<User>(initialState.user);
  const [users, setUsers] = useState<User[]>(initialState.users);
  const [agencies, setAgencies] = useState<Agency[]>(initialState.agencies);
  const [logs, setLogs] = useState<Log[]>(initialState.logs);
  const [missions, setMissions] = useState<Mission[]>(initialState.missions);
  const [boats, setBoats] = useState<Boat[]>(initialState.boats);
  const [countries, setCountries] = useState<Country[]>(initialState.countries);
  const [locations, setLocations] = useState<Location[]>(
    initialState.locations
  );
  const [uploadProgress, setUploadProgress] = useState<number>(
    initialState.uploadProgress
  );

  const [isAdmin, setIsAdmin] = useState<boolean>(initialState.isAdmin);
  const [isUser, setIsUser] = useState<boolean>(initialState.isUser);
  const [isResearcher, setIsResearcher] = useState<boolean>(
    initialState.isResearcher
  );

  const [userLogs, setUserLogs] = useState<UserLog[]>(initialState.userLogs);
  const [totals, setTotals] = useState<Totals>(initialState.totals);

  const [loading, setLoading] = useState<boolean>(initialState.loading);
  const [silentLoading, setSilentLoading] = useState<boolean>(
    initialState.silentLoading
  );
  const [error, setError] = useState<string | null>(initialState.error);

  const [pagination, setPagination] = useState<Pagination>(
    initialState.pagination
  );

  const initiateContx = async () => {
    try {
      const sess = await Auth.currentSession();
      if (sess.isValid()) {
        const payload = sess.getIdToken().decodePayload();
        setUser({
          id: payload.sub,
          email: payload.email,
          role: payload.role,
          agency_id: payload.agency_id,
          agency_name: payload.agency_name,
        });
        setIsAdmin(payload.role === Config.roles.admin);
        setIsUser(payload.role === Config.roles.user);
        setIsResearcher(payload.role === Config.roles.researcher);
        if (window.innerWidth <= 1024) {
          setPagination((pv) => ({ ...pv, perPage: 6 }));
        }
      }
    } catch {
      setUser(initialState.user);
    }
  };

  const getAgencies = async (page: number) => {
    try {
      if (!pagination?.agencies.visited.includes(page)) {
        setLoading(true);
        setError(null);
        let path = `${Config.apiPaths.agencies}?page=${page}&limit=${pagination?.perPage}`;
        const { data, paging } = await API.get(
          process.env.REACT_APP_API_NAME,
          path,
          await initOptions()
        );
        setPagination((pv) => ({
          ...pv,
          agencies: {
            visited: [...pv.agencies.visited, page],
            currentPage: page,
          },
        }));
        setTotals((pv) => ({
          ...pv,
          agencies: {
            total: paging?.total,
            visited: totals?.agencies?.visited + 1,
          },
        }));
        setAgencies((pv) => [...pv, ...data]);
        setLoading(false);
      } else {
        setPagination((pv) => ({
          ...pv,
          agencies: { ...pv.agencies, currentPage: page },
        }));
      }
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const getAllAgencies = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.agencies,
        await initOptions()
      );
      setAgencies(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const addAgency = async (name: string) => {
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.agencies,
        await initOptions({ agency: { name } })
      );
      notifySuccess(Config.messages.agencyCreated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const updateAgency = async (data: Agency) => {
    const { id, name } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.agenciesById(id),
        await initOptions({ agency: { name } })
      );
      notifySuccess(Config.messages.agencyUpdated);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const getUsers = async (page: number) => {
    try {
      if (!pagination?.users.visited.includes(page)) {
        setLoading(true);
        setError(null);
        let path = Config.apiPaths.users;
        if (isUser) {
          path += `?page=${page}&limit=${pagination?.perPage}&agency_id=${user.agency_id}&role=${Config.roles.pain_reporter}&include_inactive=true`;
        } else {
          path += `?page=${page}&limit=${pagination?.perPage}&include_inactive=true`;
        }
        const { data, paging } = await API.get(
          process.env.REACT_APP_API_NAME,
          path,
          await initOptions()
        );
        setPagination((pv) => ({
          ...pv,
          users: { visited: [...pv.users.visited, page], currentPage: page },
        }));
        setTotals((pv) => ({
          ...pv,
          users: { total: paging?.total, visited: totals?.users?.visited + 1 },
        }));
        setUsers((pv) => [...pv, ...data]);
        setLoading(false);
      } else {
        setPagination((pv) => ({
          ...pv,
          users: { ...pv.users, currentPage: page },
        }));
      }
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      setError(null);
      let path = Config.apiPaths.users;
      if (isUser) {
        path += `?limit=1000&agency_id=${user.agency_id}&role=${Config.roles.pain_reporter}&include_inactive=true`;
      } else {
        path += `?limit=1000&include_inactive=true`;
      }
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        path,
        await initOptions()
      );
      setUsers(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const addUser = async (data: User) => {
    const { agency_id, name, email, role } = data;
    try {
      setLoading(true);
      const response = await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.users,
        await initOptions({ user: { agency_id, name, email, role } })
      );
      notifySuccess(Config.messages.userCreated);
      setLoading(false);
      return { ...response, success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const updateUserStatus = async (data: User) => {
    const { id, active } = data;
    try {
      setSilentLoading(true);
      const response = await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.usersById(id),
        await initOptions({ active })
      );
      notifySuccess(Config.messages.userStatusUpdated);
      setSilentLoading(false);
      return { ...response, success: true };
    } catch (err: any) {
      setSilentLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const uploadFile = async (data: UploadFile) => {
    const { file, mission_id } = data;
    try {
      setLoading(true);
      const { data } = await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.files,
        await initOptions({ mission_id, filename: file.name })
      );
      await uploadS3WithProgress(data, file, setUploadProgress);
      notifySuccess(Config.messages.fileUploaded);
      setLoading(false);
      setUploadProgress(0);
    } catch (err: any) {
      setLoading(false);
      setUploadProgress(0);
      notifyError(`Error: ${err["message"] || err["statusText"]}`);
    }
  };

  const getLogs = async (page: number) => {
    try {
      if (!pagination?.logs.visited.includes(page)) {
        setLoading(true);
        setError(null);
        let path = `${Config.apiPaths.logs}?page=${page}&limit=${pagination?.perPage}`;
        const { data, paging } = await API.get(
          process.env.REACT_APP_API_NAME,
          path,
          await initOptions()
        );
        setPagination((pv) => ({
          ...pv,
          logs: {
            visited: [...pv.logs.visited, page],
            currentPage: page,
          },
        }));
        setTotals((pv) => ({
          ...pv,
          logs: {
            total: paging?.total,
            visited: totals?.logs?.visited + 1,
          },
        }));
        setLogs((pv) => [...pv, ...data]);
        setLoading(false);
      } else {
        setPagination((pv) => ({
          ...pv,
          logs: { ...pv.logs, currentPage: page },
        }));
      }
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const getUserLogs = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.userLogs(id),
        await initOptions()
      );
      setUserLogs(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      setError(err["message"]);
    }
  };

  const getMissions = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.missions,
        await initOptions()
      );
      setMissions(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const addMission = async (data: Mission) => {
    const { name, date_start, date_end, boat } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.missions,
        await initOptions({ mission: { name, date_start, date_end, boat } })
      );
      notifySuccess(Config.messages.missionCreated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const updateMission = async (data: Mission) => {
    const { id, name, date_start, date_end, boat_id } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.updateMission(id),
        await initOptions({ mission: { name, date_start, date_end, boat_id } })
      );
      notifySuccess(Config.messages.missionUpdated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const assignMissionToReporter = async (data: AssignMission) => {
    const { user_id, mission_id } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.assignMission(mission_id),
        await initOptions({ user_id })
      );
      notifySuccess(Config.messages.missionAssigned);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const addBoat = async (data: Boat) => {
    const { name, location_id, model } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.boats,
        await initOptions({ boat: { name, location_id, model } })
      );
      notifySuccess(Config.messages.boatCreated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const updateBoat = async (data: Boat) => {
    const { id, name, location_id, model } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.updateBoat(id),
        await initOptions({
          boat: { name, location_id, model, attributes: "" },
        })
      );
      notifySuccess(Config.messages.boatUpdated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const getBoats = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.boats,
        await initOptions()
      );
      setBoats(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const addLocation = async (data: Location) => {
    const { country_code, name } = data;
    try {
      setLoading(true);
      await API.post(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.location,
        await initOptions({ location: { country_code, name } })
      );
      notifySuccess(Config.messages.locationCreated);
      setLoading(false);
      return { success: true };
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return err;
    }
  };

  const getLocations = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.location,
        await initOptions()
      );
      setLocations(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const getCountries = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(
        process.env.REACT_APP_API_NAME,
        Config.apiPaths.country,
        await initOptions()
      );
      setCountries(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
    }
  };

  const changePassword = async (data: ChangePassword) => {
    const { oldPassword, newPassword, confirmPassword } = data;
    const isValid = checkIfNewPasswordMatch(newPassword, confirmPassword);
    if (!isValid) {
      notifyError(Config.messages.passwordDoesNotMatch);
      return;
    }
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      notifySuccess(Config.messages.passwordChanged);
      setLoading(false);
      return true;
    } catch (err: any) {
      setLoading(false);
      notifyError(`Error: ${err["message"]}`);
      return false;
    }
  };

  const logout = async () => {
    await Auth.signOut();
    navigate(Config.routes.agencies);
    setInitialStates();
  };

  const setInitialStates = () => {
    setUser(initialState.user);
    setUsers(initialState.users);
    setAgencies(initialState.agencies);
    setLogs(initialState.agencies);
    setBoats(initialState.boats);
    setPagination(initialState.pagination);
    setTotals(initialState.totals);
    setMissions(initialState.missions);
    setIsAdmin(initialState.isAdmin);
    setIsUser(initialState.isUser);
    setIsResearcher(initialState.isResearcher);
    setLoading(initialState.loading);
    setSilentLoading(initialState.silentLoading);
    setError(initialState.error);
    setUploadProgress(0);
  };

  const data = {
    user,
    users,
    agencies,
    logs,
    boats,
    missions,
    locations,
    countries,
    userLogs,
    uploadProgress,
    loading,
    silentLoading,
    error,
    isAdmin,
    isUser,
    isResearcher,
    pagination,
    totals,
    initiateContx,
    getAgencies,
    getAllAgencies,
    addAgency,
    updateAgency,
    getUsers,
    getAllUsers,
    addUser,
    updateUserStatus,
    uploadFile,
    getLogs,
    getBoats,
    addBoat,
    updateBoat,
    addLocation,
    getLocations,
    getMissions,
    addMission,
    updateMission,
    getCountries,
    getUserLogs,
    assignMissionToReporter,
    changePassword,
    logout,
  };

  return data;
};

const initOptions = async (body: any = null) => {
  const Authorization = await getAccessToken();
  let options = {
    headers: {
      Authorization,
    },
  };
  if (body) {
    options["body"] = body;
  }
  return options;
};

const getAccessToken = async () => {
  let valid = false;
  try {
    const currentSession = await Auth.currentSession();
    valid = currentSession.isValid();
  } catch {}
  if (valid) {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }
  return null;
};

const uploadS3WithProgress = (data, file, progressCallback) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 204) {
          resolve(xhr);
        } else {
          reject(xhr);
        }
      }
    };

    if (progressCallback) {
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentComplete = (e.loaded / file.size) * 100;
          progressCallback(percentComplete.toFixed(0));
        }
      };
    }

    const formData = new FormData();
    for (const f in data.fields) {
      formData.append(f, data.fields[f]);
    }

    formData.append("file", file);
    xhr.open("POST", data.url, true);

    xhr.send(formData);
  });
};

const checkIfNewPasswordMatch = (newPassword, confirmPassword) =>
  newPassword === confirmPassword;

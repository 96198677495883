import React, { useState } from "react";
import Config from "../../../config";
import moment from "moment";

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Badge,
  Menu,
  MenuItem,
  MenuButton,
} from "@aws-amplify/ui-react";
import { MissionsTableProps } from "@/types";
import { defineSortIcon } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";

const MissionsTable = (props: MissionsTableProps) => {
  const { missions } = props;
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...missions].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return missions;
  }, [missions, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const header = (
    <TableRow>
      <TableCell as="th" onClick={() => requestSort("name")} className="hh hhb">
        Name
        {defineSortIcon(sortConfig.key, sortConfig.direction, "name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("boat_name")}
        className="hh hhb"
      >
        Boat
        {defineSortIcon(sortConfig.key, sortConfig.direction, "boat_name")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("date_start")}
        className="hh hhb"
      >
        From
        {defineSortIcon(sortConfig.key, sortConfig.direction, "date_start")}
      </TableCell>
      <TableCell
        as="th"
        onClick={() => requestSort("date_end")}
        className="hh hhb"
      >
        To
        {defineSortIcon(sortConfig.key, sortConfig.direction, "date_end")}
      </TableCell>
      <TableCell as="th" className="hh">
        Operators
      </TableCell>
      <TableCell style={{ width: "8%" }} as="th" className="hh">
        Actions
      </TableCell>
    </TableRow>
  );

  const content = sortedData?.map((x) => (
    <TableRow key={x.id}>
      <TableCell>{x.name}</TableCell>
      <TableCell>{x.boat_name}</TableCell>
      <TableCell>{moment(x.date_start).format("YYYY-MM-DD")}</TableCell>
      <TableCell>{moment(x.date_end).format("YYYY-MM-DD")}</TableCell>
      <TableCell>
        {x?.users.length && !!x?.users[0].id
          ? x?.users.map((x, i) => (
              <Badge key={i} marginRight={5} border="1px solid #dac57a">
                {x.name}
              </Badge>
            ))
          : ""}
      </TableCell>
      <TableCell style={{width: '8%'}}>
        <Menu
          trigger={
            <MenuButton
              variation="menu"
              border="1px solid lightgray"
              size="large"
              width="100%"
              justifyContent="center"
            >
              <FiMoreHorizontal />
            </MenuButton>
          }
        >
          <MenuItem
            onClick={() => navigate(Config.routes.updateMission, { state: x })}
          >
            Update
          </MenuItem>
          <MenuItem
            onClick={() =>
              navigate(Config.routes.assignToAMission, { state: x })
            }
          >
            Assign Operator
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Table caption="">
        <TableHead>{header}</TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </>
  );
};

export default MissionsTable;

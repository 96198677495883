import React from "react";

import { Button } from "@aws-amplify/ui-react";
import { FormActionsProps } from "@/types";

const FormActions = (props: FormActionsProps) => {
  const { formOnSubmit, isLoading, isDisabled } = props;

  const handleSubmit = (e) => {
    formOnSubmit();
    e.preventDefault();
  };

  return (
    <>
      <Button
        type="submit"
        variation="primary"
        width="100%"
        marginTop="31px"
        onClick={handleSubmit}
        isLoading={isLoading}
        loadingText={props.loadingText || 'Loading...'}
        isDisabled={isDisabled}
      >
        {props.text}
      </Button>
    </>
  );
};

export default FormActions;
